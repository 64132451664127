<template lang="pug">
IonButton(
    color="transparent"
    size="large"
    expand="block"
    style="height: 65px; --border-radius: 0; --padding-start: 0; --padding-end: 0"
)
    IonRow(
        style="width: 100%"
        class="custom-row"
    )
        IonCol(
            size="10"
            class="text-col pt-3 pb-3 overflow-hidden d-flex align-center"
            style="min-height: 65px; background-color: var(--ion-color-tertiary)"
            class="ion-activatable ripple-parent radius-right ion-padding-start"
        )
            ion-ripple-effect
            //- avatar
            img(
                style="width: 45px; height: 45px; border-radius: 50%; object-fit: cover"
                :src="person.avatar"
            )
            //- name
            h3(
                class="ion-padding-start"
                style="color: white"
            ) {{ person.name }}

        IonCol(
            size="2"
            style="min-height: 65px; background-color: var(--ion-color-accent)"
            class="ion-activatable button-col overflow-hidden justify-center ripple-parent ion-no-padding d-flex align-center justify-center radius-left"
        )
            ion-ripple-effect
            div(class="item-icon d-flex align-center justify-center")
                IonIcon(
                    :icon="chevronForwardCircle"
                    color="secondary"
                    style="font-size: 22px"
                )
</template>

<script>
import { computed } from "vue"
import { chevronForwardCircle } from "ionicons/icons"
export default {
    props: {
        person: {
            type: Object,
            required: true
        }
    },

    setup(props) {
        chevronForwardCircle
        return { chevronForwardCircle }
    }
}
</script>

<style lang="sass">
.custom-row
    .check-col
        ion-button
            margin: 0

</style>