<template lang="pug">
div
    //- name bar
    TopBar
        IonText(
            color="secondary"
        )
            h1 Messenger

    Spacer
    IonText(
        color="secondary"
    )
        h3(class="ion-padding-start") Konversationen
    Spacer
    //- list
    div
        ChatPerson(
            v-for="(person, index) in chatPersons"
            :key="index"
            :person="person"
            @click="$router.push({ name: 'messenger-id', params: { id: person.id } })"
        )
</template>

<script>
import TopBar from "@/components/sections/TopBar"
import ChatPerson from "@/components/molecules/messenger/ChatPerson"
import { ref } from "vue"
export default {
    components: { TopBar, ChatPerson },

    setup() {
        const chatPersons = ref([
            {
                name: "Max Mustermann",
                avatar: require(`@/assets/temp/avatar-1.jpeg`),
                id: "25fvssr4"
            },
            {
                name: "Johan Wehner",
                avatar: require(`@/assets/temp/avatar-2.jpeg`),
                id: "3darf4sah"
            },
            {
                name: "Moritz Mayer",
                avatar: require(`@/assets/temp/avatar-3.jpeg`),
                id: "5dsg35dgd"
            }
        ])
        return { chatPersons }
    }
}
</script>