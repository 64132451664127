<template lang="pug">
BaseLayout
    ChatList
</template>

<script>
import { useState } from "@/composables/state.js"
import ChatList from "@/components/molecules/messenger/ChatList"
export default {
    components: { ChatList },
    setup() {
        const { orders } = useState()

        return { orders }
    }
}
</script>